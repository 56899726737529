<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Meta</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select v-model="meta.cliente_id" @change="loadSucursales" :items="clientes" label="Cliente" item-value="id" item-text="razon_social" :error-messages="errors.cliente_id"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-model="meta.sucursal_id" :items="sucursales" label="Sucursal" item-value="id" item-text="nombre" :error-messages="errors.sucursal_id" class="required"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="meta.cantidad" label="Cantidad" :error-messages="errors.cantidad" class="required"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-menu v-model="menu_fecha" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="meta.fecha" label="Fecha de inicio" readonly v-on="on" :error-messages="errors.fecha" class="required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="meta.fecha" @input="menu_fecha = false" type="month"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="save" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$emit('close')" :loading="loading">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            metaId: Number,
        },
        data: () => ({
            dialog: false,
            meta: {},
            clientes: [],
            sucursales: [],
            errors: {},
            loading: false,
            menu_fecha: false
        }),
        watch: {
            show(newVal, oldVal) {
                this.dialog = newVal
                this.errors = {}
                this.meta = {}
                this.load()
            },
            metaId(newVal) {
                this.load()
            }
        },
        mounted() {
            this.loadClientes()
            this.load()
        },
        methods: {
            loadClientes() {
                this.$http.get('/clientes/all').then(response => {
                    this.clientes = response.data
                })
            },
            loadSucursales() {
                this.$http.get(`/clientes/${this.meta.cliente_id}/sucursales`).then(response => {
                    this.sucursales = response.data
                })
            },
            load() {
                if (this.metaId > 0) {
                    this.$http.get(`/metas/${this.metaId}`).then(response => {
                        this.meta = {
                            id: response.data.id,
                            cantidad: response.data.cantidad,
                            fecha: response.data.mes,
                            cliente_id: response.data.sucursal.cliente.id,
                            sucursal_id: response.data.id
                        }
                        this.loadSucursales()
                    })
                }
            },
            save() {
                this.loading = true
                if (this.meta.id) {
                    this.update()
                    return
                }
                this.create()
            },
            update() {
                let meta = {}
                Object.assign(meta, this.meta)

                meta.fecha = `${meta.fecha}-01 00:00:00`
                this.$http.put(`/metas/${this.meta.id}`, meta).then(response => {
                    this.$emit('close');
                }).catch(error => {
                    this.errors = error.response.data.errors
                }).finally(() => {
                    this.loading = false
                })
            },
            create() {
                let meta = {}
                Object.assign(meta, this.meta)

                meta.fecha = `${meta.fecha}-01 00:00:00`
                this.$http.post(`/metas`, meta).then(response => {
                    this.$emit('close');
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors

                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>